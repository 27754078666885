import applicationTypeFromStore from '@/modules/application-type/store/application-type-form-store';
import applicationTypeListStore from '@/modules/application-type/store/application-type-list-store';

export default {
    namespaced: true,

    modules: {
        form: applicationTypeFromStore,
        list: applicationTypeListStore,
    },
};
