import { ApplicationTypeService} from '@/modules/application-type/application-type-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_REMOVE = 'remove';
export const DO_SELECT = 'select';
export const DO_UNSELECT = 'unselect';

// mutation types
export const SET_SELECTED = 'setSelected';
export const REMOVE_SELECTED = 'removeSelected';
export const UPDATE_SUCCESS = 'updateSuccess';

const state = {
    record: null,
    openEditing: false,
};

const getters = {
    record: (state) => state.record,
    openEditing: (state) => state.openEditing,
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'applicationType/create', { root: true });
            ApplicationTypeService.create(values)
                .then(() => {
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    context.commit(UPDATE_SUCCESS);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applicationType/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'applicationType/update', { root: true });
            ApplicationTypeService.update(id, values)
                .then((record) => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    context.commit(UPDATE_SUCCESS);
                    context.commit('applicationType/list/updateRecord', { ...record }, { root: true });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applicationType/update', { root: true });
                });
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'applicationType/remove', { root: true });
            ApplicationTypeService.remove(id)
                .then(() => {
                    context.commit('applicationType/list/removeRecord', id, { root: true });
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applicationType/remove', { root: true });
                });
        });
    },
    [DO_SELECT](context, record) {
        context.commit(SET_SELECTED, record);
    },
    [DO_UNSELECT](context) {
        context.commit(REMOVE_SELECTED);
    }
};

const mutations = {
    [SET_SELECTED](state, record) {
        state.record = record;
        state.openEditing = true;
    },
    [REMOVE_SELECTED](state) {
        state.record = {};
        state.openEditing = false;
    },
    [UPDATE_SUCCESS](state) {
        state.openEditing = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
